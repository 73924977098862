const title = "StockDash Portfolio Tracker";
const description =
  "Meer inzicht in uw beleggingen. Nu gratis toegang tot uw persoonlijke online portfolio dashboard. Zowel voor desktop als op mobiel!.";

const share = require("assets/images/share.png");

export const helmet = {
  title,
  titleTemplate: "%s - StockDash",
  htmlAttributes: { lang: "nl" },
  script: [
    {
      type: "text/javascript",
      innerHTML: 'var STONLY_WID = "277f17fa-5f04-11eb-accc-0ad3430e55ea";',
    },
    {
      type: "text/javascript",
      innerHTML: `
      !function(s,t,o,n,l,y,w,g){s.StonlyWidget||((w=s.StonlyWidget=function(){
      w._api?w._api.apply(w,arguments):w.queue.push(arguments)}).queue=[],(y=t.createElement(o)).async=!0,
      (g=new XMLHttpRequest).open("GET",n+"version?v="+Date.now(),!0),g.onreadystatechange=function(){
      4===g.readyState&&(y.src=n+"stonly-widget.js?v="+(200===g.status?g.responseText:Date.now()),
      (l=t.getElementsByTagName(o)[0]).parentNode.insertBefore(y,l))},g.send())
      }(window,document,"script","https://stonly.com/js/widget/v2/");`,
    },
  ],
  meta: [
    { name: "description", content: description },
    {
      name: "viewport",
      content: "width=device-width, initial-scale=1, user-scalable=no",
    },
    { name: "apple-mobile-web-app-status-bar-style", content: "#1e2221" },
    { name: "msapplication-navbutton-color", content: "#1e2221" },
    { name: "msapplication-TileColor", content: "#1e2221" },
    { name: "theme-color", content: "#1e2221" },

    { property: "og:title", content: title },
    { property: "og:image", content: share },
    { property: "og:image:width", content: "880px" },
    { property: "og:image:height", content: "440px" },
    { property: "og:image:alt", content: description },

    { name: "twitter:title", content: title },
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:image", content: share },
    { name: "twitter:site", content: "@uenodotco" },
    { name: "twitter:creator", content: "@uenodotco" },
    { name: "twitter:description", content: description },
  ],
  link: [
    { rel: "icon", href: require("assets/site/favicon.ico") },
    {
      rel: "apple-touch-icon",
      sizes: "180x180",
      href: require("assets/site/apple-touch-icon.png"),
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "32x32",
      href: require("assets/site/favicon-32x32.png"),
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "16x16",
      href: require("assets/site/favicon-16x16.png"),
    },
    { rel: "mask-icon", href: require("assets/site/safari-pinned-tab.svg") },
  ],
};
