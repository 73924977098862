import * as React from "react";
import Helmet from "react-helmet";

import { helmet } from "../../utils/helmet";
import { Header } from "../header/Header";
import { Devtools } from "../devtools/Devtools";
import { isDev } from "../../utils/common";
import s from "./AppLayout.scss";
import { Button } from "../button/Button";
// import { Link as HeaderLink } from "components/header/Link";

interface IAppLayoutProps {
  children: React.ReactNode;
}

export default ({ children }: IAppLayoutProps) => (
  <>
    <Helmet {...helmet} />
    <div className={s.layout}>
      <Header>
        <Button target="_self" type="primary" to="https://app.stockdash.nl">
          Login
        </Button>
      </Header>
      {children}

      {isDev && <Devtools />}
    </div>
  </>
);
